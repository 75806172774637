// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$RondeivuBeta-primary: mat.define-palette(mat.$blue-palette, 700);
$RondeivuBeta-accent: mat.define-palette(mat.$orange-palette, A100);
// The warn palette is optional (defaults to red).
$RondeivuBeta-warn: mat.define-palette(mat.$red-palette, 900);

$RondeivuBeta-typography-md: mat.define-typography-level(
  $font-family: 'Segoe UI',
  $font-weight: 400,
  $font-size: 1rem,
  $line-height: 1.2,
  $letter-spacing: normal
);

$RondeivuBeta-typography-sm: mat.define-typography-level(
  $font-family: 'Segoe UI',
  $font-weight: 400,
  $font-size: 0.88rem,
  $line-height: 1.2,
  $letter-spacing: normal
);

$RondeivuBeta-typography: mat.define-typography-config(
  $font-family: 'Segoe UI',
  $display-1: $RondeivuBeta-typography-sm,
  $display-2: $RondeivuBeta-typography-sm,
  $display-3: $RondeivuBeta-typography-sm,
  $display-4: $RondeivuBeta-typography-sm,
  $headline: $RondeivuBeta-typography-md,
  $title: $RondeivuBeta-typography-md,
  $subheading-2: $RondeivuBeta-typography-sm,
  $subheading-1: $RondeivuBeta-typography-sm,
  $body-2: $RondeivuBeta-typography-sm,
  $body-1: $RondeivuBeta-typography-sm,
  $caption: $RondeivuBeta-typography-sm,
  $button: $RondeivuBeta-typography-sm,
  $input: $RondeivuBeta-typography-sm
);


// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$RondeivuBeta-theme: mat.define-light-theme((
  color: (
    primary: $RondeivuBeta-primary,
    accent: $RondeivuBeta-accent,
    warn: $RondeivuBeta-warn,
  ),
  typography: $RondeivuBeta-typography
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($RondeivuBeta-theme);

// root color variables
:root {
  --rondeivu-blue: #002150;
  --rondeivu-blue-2: #0047AB;
  --nav-highlight: #f8a03b;

  .block-container {
    display: block !important; 
    width: 100%; 
    height: 100%;
    box-sizing: border-box;
  }

  .test {
    border: thick solid orange !important;
  }

  .router-container {
    display: block; 
    width: 100%; 
    height: 100%; 
    box-sizing: border-box;
  }

  .container-fluid {
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    &.with-padding {
      padding: 35px 65px !important;
    }

    &.width-content-padding {
      padding: 0px 65px 35px 65px;
    }

    &.scroll {
      overflow-y: auto;
    }
  }

  .date-stacked {
    display: flex;
    height: 100%;
    flex-direction: column;
    gap: 0px;

    span:last-child { font-size: smaller; color: #666; }
  }
}

@font-face {
  font-family: "Segoe UI";
  src: url(assets/fonts/segoeui/segoeui.ttf) format("opentype");
}

@font-face {
  font-family: "Poppins";
  src: url(assets/fonts/Poppins/Poppins-Regular.ttf) format("opentype");
}

.mat-dialog-container {
  border-radius: 12px !important;
}

.form-field{
  width: 100%;
}


/* You can add global styles to this file, and also import other style files */

.col-right {
  text-align: end;
}

html, body {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: "Segoe UI", sans-serif !important;
}

.space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

ol.formatted {
  display: flex; 
  flex-direction: column; 
  gap: 6px;
  padding: 0px;
  margin: 10px 0px;

  li {
    display: flex; 
    align-items: center; 
    gap: 6px;
  }
}

.align-center {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 6px;
  margin: 10px 0px;
}

.large-number {
  mat-icon { font-size: 50px; }
}

//for blacking out the modal background
.black-out-modal {
  background: url("/assets/images/modal-bg.png");
  background-color: var(--rondeivu-blue);
  //backdrop-filter: blur(10px);
}

// Global Rondeivu Styles
#nav-list {
  .mat-list-item-content {
    padding: 0 10px !important;
  }
}
// the modal header
.rm-header {
  width: calc(100% + 48px);
  height: 50px;
  padding: 15px;
  color: white;
  font-size: x-large;
  background-color: var(--rondeivu-blue);
  margin-left: -24px;
}

// the page header
.rm-title {
  font-family: "Segoe UI", sans-serif !important;
}

// size 3
.rm-title-3 {
  color: #184BA1;
  font-size: 1.5rem;
}

// size 2.5
.rm-title-2-5 {
  color: #184BA1;
  font-size: 1.3rem;
}

// size 2
.rm-title-2 {
  color: #184BA1;
  font-size: 1.1rem;
}

// size 6
.rm-title-6 {
  color: #0f316c;
  font-size: 0.88rem;
}

.rm-lighter {
  font-weight: lighter;
}

// the header icon
.rm-header-icon {
  margin: auto 0;
}

// custom mat dialog
.mat-dialog-container {
  padding-top: 0 !important;
}

.mat-dialog-content {
  padding-top: 25px !important;
}

.mat-dialog-actions {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mat-dialog-title {
  margin: 15px !important;
}

//custom mat tab
.mat-tab-link {
  background-color: #E8EDF6;
}

//custom mat table
.mat-table {
  border-collapse: separate;
}

.form-field {
  //width: 100%;
}

//todo decide on vertical center for form inputs
.mat-form-field-appearance-outline .mat-form-field-infix {
  margin-top: -5px !important;
}

// mat-tables striped rows
.mat-row:nth-child(even) {
  background-color: rgba(245, 245, 245, 0.5);
}

.mat-row:nth-child(odd) {
  background-color: white;
}

/* Row hover background */
.mat-row:hover .mat-cell {
  background-color: rgba(225, 161, 0, 0.1);
}

//custom app scrollbars
/* width */
.custom-scroll::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
.custom-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.4);
}

/* Handle on hover */
.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 116, 3, 0.5);
}
